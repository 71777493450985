import React, { useState } from "react";
import {
  Box,
  Container,
  Flex,
  Heading,
  Link,
  Text,
  HStack,
} from "@chakra-ui/react";
import Portfolio from './Portfolio'; 

const Prvn = () => {
  const [currentVisibleSection, setCurrentVisibleSection] = useState("home");

  const toggleSection = (sectionId) => {
    if (sectionId === currentVisibleSection) return;
    setCurrentVisibleSection(sectionId);
  };

  return (
    <Box>
      <Box bg="brand.800" color="white" py={4}>
        <Container maxW="container.lg">
          <Flex justify="space-between" align="center">
            <Heading size="xl">PRVN</Heading>
            <HStack spacing={4}>
              <Link
                href="#home"
                onClick={() => toggleSection("home")}
                color={
                  currentVisibleSection === "home" ? "brand.300" : "white"
                }
              >
                Home
              </Link>
              <Link
                href="#contact"
                onClick={() => toggleSection("contact")}
                color={currentVisibleSection === "contact" ? "brand.300" : "white"}
              >
                Contact
              </Link>

            </HStack>
          </Flex>
        </Container>
      </Box>

      <Box
        py={5}
        bg="brand.100"
        display={currentVisibleSection === "home" ? "block" : "none"}
      >
        <Portfolio />
      </Box>  
      
      <Box
        py={5}
        display={currentVisibleSection === "contact" ? "block" : "none"}
      >
        <Container maxW="container.lg">
          <Heading as="h2" size="xl" mb={4}>
            Contact us
          </Heading>
          <Text>Add your description here.</Text>
        </Container>
      </Box>

    
    </Box>
  );
};

export default Prvn;
