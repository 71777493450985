import React from 'react';
import { Container, Heading, Text } from '@chakra-ui/react';

const Portfolio = () => (
  
  <Container maxW="container.lg">
    <Heading as="h2" size="xl" mb={4}>
      Home
    </Heading>
    <Text>This is the app screen</Text>
  </Container>
);

export default Portfolio;